import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { Refresh, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import logoWhite from "../../../images/axial-shift-black-icon-white-rounded-background.png";
import logoBlack from "../../../images/axial-shift-white-icon-black-rounded-background.png";

import { api } from "../../../App";
import { AccountDetailsContext } from "../../message/models/AccountDetailsContext";
import { UserContext } from "../../message/models/UserContext";
import { AuthControllerTasks } from "../controllers/AuthController";
import { AuthActions } from "../data/AuthActions";

export default function Auth() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const { accountDetails, setAccountDetails } = useContext(
    AccountDetailsContext.AccountDetailsContext
  );

  useEffect(() => {
    const currentQueryParameterAuth = new URLSearchParams(
      window.location.search
    ).get("at");

    if (currentQueryParameterAuth) {
      console.log("Auth Token Query Params Exist");
      AuthControllerTasks(AuthActions.GET_AXIAL_SESSION_DATA, {
        accountDetails: accountDetails,
        token: currentQueryParameterAuth,
        callback: (sessionInfo, token) => {
          handleSessionInformation(sessionInfo, token);
          sendUserToApp();
        },
      });
    }
  }, []);

  const [snackbar, setSnackbar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    severity: "info",
    message: "",
  });

  const [buttonState, setButtonState] = useState({
    loginDisabled: false,
    continueDisabled: true,
    showButtonLoading: false,
  });

  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: "",
    showPassword: false,
  });

  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({
    id: "",
    name: "",
  });
  const [featureFlagsByCompanyId, setFeatureFlagsByCompanyId] = useState([]);

  const [accountList, setAccountList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({
    id: "",
    name: "",
    sendChange: false,
  });

  const { currentUser, setCurrentUser } = useContext(UserContext.UserContext);
  const navigate = useNavigate();

  const logo = prefersDarkMode ? logoWhite : logoBlack;

  const sendCredentials = () => {
    console.log(`Attempting to login ${loginDetails.username}`);
    setButtonState({
      ...buttonState,
      loginDisabled: true,
      showButtonLoading: true,
    });
    setSelectedCompany({ id: "", name: "" });
    AuthControllerTasks(AuthActions.SEND_AXIAL_AUTH_DETAILS, {
      accountDetails: accountDetails,
      authData: {
        accountId: selectedAccount.id === "" ? undefined : selectedAccount.id,
        username: loginDetails.username,
        password: loginDetails.password,
      },
      callback: handleAuthentication,
    });
  };

  /**
   * Good Response
   * {
   *     "result": {
   *         "accessToken": "<the token data>",
   *         "expiresIn": 604800,
   *         "accountId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
   *         "noActiveAccounts": false,
   *         "isFreeTier": false,
   *         "mustChooseAccount": false,
   *         "accountsToChooseFrom": [],
   *         "companies": [
   *             {
   *                 "value": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
   *                 "label": "Axial Commerce",
   *                 "name": "Axial Commerce"
   *             }
   *         ],
   *         "sendToDashboard": false
   * }
   * A bad response has result: null
   * @param status
   * @param authDetails
   * @param data
   */
  const handleAuthentication = (status, authDetails, data) => {
    switch (status) {
      case 200:
        if (data.result !== null && data.result.accessToken !== null) {
          openSnackbar("Successfully logged in", "success");
          AuthControllerTasks(AuthActions.GET_AXIAL_SESSION_DATA, {
            accountDetails: accountDetails,
            token: data.result.accessToken,
            callback: handleSessionInformation,
          });
        } else {
          openSnackbar(data.messages[0].message, "error");
        }
        return;
      default:
        setButtonState({ ...buttonState, loginDisabled: false });

        openSnackbar(data.messages[0].message, "error");
        return;
    }
  };

  /**
   * Need to extract companies for selected account and a list of all accounts.
   * If > 1 account we need to offer a choice to select another one.
   *
   * @param sessionInfo - axios response from status/session call
   */
  const handleSessionInformation = async (sessionInfo, token) => {
    api.setAuthToken(token);
    if (sessionInfo === undefined || token === undefined) {
      setButtonState({ ...buttonState, loginDisabled: false });
      openSnackbar("Login Session Has Expired, Please Login Again.", "error");

      return;
    }
    const ownJobsResponse = await api.getEmployeesJobs();

    setButtonState({ ...buttonState, loginDisabled: false });

    setAccountList([...sessionInfo.accounts]);
    setCompanyList([...sessionInfo.companies]);
    setCurrentUser({
      ...currentUser,
      id: sessionInfo.userId,
      activeCompanyId: sessionInfo.company.id,
      firstName: sessionInfo.userFirstName,
      lastName: sessionInfo.userLastName,
      displayName: sessionInfo.userFullName,
      hasAnyPermissions:
        Object.values(sessionInfo.permissions).filter((t) => t > 0).length > 0,
      isSuperUser: sessionInfo.isSuperUser,
      isAdmin: sessionInfo.isAdmin,
      isOwner: sessionInfo.isOwner,
      permissions: sessionInfo.permissions,
      ownJobs: ownJobsResponse.data.result,
      companies: sessionInfo.companies.map((company) => ({
        ...company,
        companyId: company.id,
      })),
    });
    setSelectedAccount({
      id: sessionInfo.current.accountId,
      name: sessionInfo.accountName,
      sendChange: false,
    });

    if (
      checkFeatureFlag(sessionInfo.featureFlagsByCompanyId, sessionInfo.company)
    ) {
      setButtonState((prevState) => ({
        ...prevState,
        continueDisabled: false,
      }));

      setSelectedCompany({
        id: sessionInfo.company.id,
        name: sessionInfo.company.name,
      });
    }
    setAccountDetails({
      ...accountDetails,
      token: token,
      axial: {
        ...accountDetails.axial,
        user: {
          id: sessionInfo.userId,
          firstName: sessionInfo.userFirstName,
          lastName: sessionInfo.userLastName,
        },
        account: {
          name: sessionInfo.accountName,
          id: sessionInfo.accountId,
        },
        company: {
          id: sessionInfo.company.id,
          name: sessionInfo.company.name,
        },
      },
      accounts: [...sessionInfo.accounts],
      companies: [...sessionInfo.companies],
    });
    setFeatureFlagsByCompanyId(sessionInfo.featureFlagsByCompanyId);

    api.setCompanyId(sessionInfo.company.id);
    api.setAccountId(sessionInfo.current.accountId);
  };

  const sendUserToApp = () => {
    if (selectedCompany) {
      setButtonState({ ...buttonState, continueDisabled: true });
      /*
        AuthControllerTasks(AuthActions.SEND_INTERNAL_AUTH_DETAILS, {
            accountDetails: accountDetails,
            callback: handleUserSync
        });
         */
      navigate("/home");
    }
  };

  /*
    const handleUserSync = (status, data) => {
        switch (status) {
            case 200:
                setAccountDetails({
                    ...accountDetails,
                    accountId: data.account.id,
                    companyId: data.company.id,
                    currentUserId: data.user.id
                });
                setCurrentUser({
                    ...currentUser,
                    id: data.user.id,
                    firstName: data.user.firstName,
                    lastName: data.user.lastName,
                    displayName: data.user.displayName
                });
                navigate("/home");
                return;
            default:
                openSnackbar("[1543] An error occurred", "error");
                return;
        }
    }
  */

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const openSnackbar = (msg, severity) => {
    setSnackbar({ ...snackbar, open: true, severity: severity, message: msg });
  };

  const handleClickShowPassword = () => {
    setLoginDetails({
      ...loginDetails,
      showPassword: !loginDetails.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginDetailsChange = (field, newValue) => {
    setLoginDetails({ ...loginDetails, [field]: newValue });
  };

  const handleAccountChange = (event) => {
    const account = accountList.find(
      (account) => account.id === event.target.value
    );
    if (account !== null) {
      setSelectedAccount({
        id: account.id,
        name: account.name,
        sendChange: true,
      });
      api.setAccountId(account.id);
      setAccountDetails({
        ...accountDetails,
        axial: {
          ...accountDetails.axial,
          account: { id: account.id, name: account.name },
        },
      });
    }
  };

  useEffect(() => {
    if (
      selectedAccount !== undefined &&
      selectedAccount.id.length === 36 &&
      selectedAccount.sendChange === true
    ) {
      sendCredentials();
    }
  }, [selectedAccount]);

  const handleCompanyChange = (event) => {
    const company = companyList.find(
      (company) => company.id === event.target.value
    );
    if (company !== null) {
      if (checkFeatureFlag(featureFlagsByCompanyId, company)) {
        setCompany(company);
      } else {
        openSnackbar(
          "Messaging feature is not enabled for this company",
          "error"
        );
      }
    }
  };

  /**
   * Sets selected company for the app.
   * @param {Object} company
   */
  const setCompany = (company) => {
    setButtonState((prevState) => ({
      ...prevState,
      continueDisabled: false,
    }));

    setSelectedCompany({ id: company.id, name: company.name });
    api.setCompanyId(company.id);
    setAccountDetails({
      ...accountDetails,
      axial: {
        ...accountDetails.axial,
        company: { id: company.id, name: company.name },
      },
    });
  };

  /**
   * Checks if company selected has the Messaging Feature Flag.
   * @param {Array} featureFlagsByCompanyId
   * @param {Object} selectedCompany
   * @returns {boolean}
   */
  const checkFeatureFlag = (featureFlagsByCompanyId, selectedCompany) => {
    const featureFlags = featureFlagsByCompanyId[selectedCompany.id];
    if (featureFlags) {
      return featureFlags.find((feature) => feature === 0) !== undefined; // 0 = Enum for Messaging Feature Flag.
    }

    return false;
  };

  const getAccountSelect = () => {
    return (
      <>
        <FormControl sx={{ width: "100%", mt: 2 }} variant="outlined">
          <InputLabel id={`account-select-label`}>Account</InputLabel>
          <Select
            labelId={`account-select-label`}
            id={`account-select`}
            label="Account"
            value={selectedAccount.id}
            onChange={handleAccountChange}
          >
            {accountList.map((account) =>
              account.isActive === true ? (
                <MenuItem key={account.id} value={account.id}>
                  {account.name}
                </MenuItem>
              ) : null
            )}
          </Select>
        </FormControl>
      </>
    );
  };

  const renderAccountName = () => {
    const accountName = accountList[0] === undefined ? "" : accountList[0].name;
    return (
      <>
        <FormControl sx={{ width: "100%", mt: 2 }} variant="outlined">
          <TextField
            label="Account"
            defaultValue={accountName}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
      </>
    );
  };

  const getCompanySelect = () => {
    return (
      <>
        <FormControl sx={{ width: "100%", mt: 2 }} variant="outlined">
          <InputLabel id={`company-select-label`}>Company</InputLabel>
          <Select
            labelId={`company-select-label`}
            id={`company-select`}
            label="Company"
            value={selectedCompany.id}
            onChange={handleCompanyChange}
          >
            <MenuItem key="init-company-list" value="">
              <em>Company</em>
            </MenuItem>
            {companyList.map((company) =>
              company.isActive === true ? (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ) : null
            )}
          </Select>
        </FormControl>
      </>
    );
  };

  const renderLoadingIcon = () => {
    if (!buttonState.showButtonLoading) {
      return null;
    }

    return (
      <Refresh
        sx={{
          animation: "spin 2s linear infinite",
          "@keyframes spin": {
            "0%": {
              transform: "rotate(0deg)",
            },
            "100%": {
              transform: "rotate(360deg)",
            },
          },
        }}
      />
    );
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Grid
          container
          sx={{
            width: "50%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={matches ? 12 : 6} sx={{ textAlign: "center" }}>
            <Box
              component="img"
              src={logo}
              sx={{ width: matches ? "90%" : "80%", p: 3 }}
            />
          </Grid>
          <Grid item xs={matches ? 12 : 6} sx={{ textAlign: "left" }}>
            {selectedAccount.id.length === 0 ? (
              <>
                <FormControl sx={{ width: "100%", mt: 2 }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-username">
                    Email
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-username"
                    type="text"
                    value={loginDetails.username}
                    label="Email"
                    onChange={(e) =>
                      handleLoginDetailsChange("username", e.target.value)
                    }
                  />
                </FormControl>
                <FormControl sx={{ width: "100%", mt: 2 }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={loginDetails.showPassword ? "text" : "password"}
                    value={loginDetails.password}
                    onChange={(e) =>
                      handleLoginDetailsChange("password", e.target.value)
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={(e) => handleMouseDownPassword(e)}
                          edge="end"
                        >
                          {loginDetails.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </>
            ) : null}
            {accountList.length > 0
              ? accountList.length > 1
                ? getAccountSelect()
                : renderAccountName()
              : null}
            {companyList.length > 0 ? getCompanySelect() : null}
            <Box sx={{ textAlign: "right", mt: 2 }}>
              {companyList.length > 0 ? (
                <Button
                  variant="contained"
                  disabled={buttonState.continueDisabled}
                  onClick={sendUserToApp}
                >
                  Continue
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled={buttonState.loginDisabled}
                  onClick={sendCredentials}
                >
                  {renderLoadingIcon()}Login
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: snackbar.vertical,
          horizontal: snackbar.horizontal,
        }}
        open={snackbar.open}
        onClose={closeSnackbar}
        key={snackbar.vertical + snackbar.horizontal}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </>
  );
}
